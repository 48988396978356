export class AppLanguage {

    private languageParams: LanguageParams;

    constructor(languageParams: LanguageParams) {
        this.languageParams = languageParams;
    }

    public id(): string {
        return this.languageParams.id;
    }

    public name(): string {
        return this.languageParams.name;
    }

    public localeId(): string {
        return this.languageParams.localeId;
    }

    public iconName(): string {
        return this.languageParams.iconName;
    }
}

interface LanguageParams {
    id: string;
    name: string;
    localeId: string;
    iconName?: string;
}
