import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {LocalSettingsService} from '../services/local-settings.service';

@Injectable({
    providedIn: 'root'
})
export class HasLanguageSetGuard implements CanActivate {

    constructor(private localSettingsService: LocalSettingsService,
                private router: Router) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
        const selectedLanguage = this.localSettingsService.selectedLanguage();
        const urlParams: any = next.params;
        const languageFromUrl = this.localSettingsService.availableLanguages().find(language => language.id() === urlParams.language);
        return languageFromUrl ? true : this.router.parseUrl(selectedLanguage.id());
    }

    canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
        return this.canActivate(next, state);
    }
}
