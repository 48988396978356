import {AppLanguage} from './app-language';

export class AppLanguageLv extends AppLanguage {

    constructor() {
        super({
            id: 'lv',
            name: 'Latviešu',
            localeId: 'lv-LV',
            iconName: 'flags/lv'
        });
    }
}
