import {JsonObject} from './json-object.interface';

export class JsonEncoder {

    private included: Array<any> = [];

    encode(items: any): any {
        let encodedItems: any;
        // Add data object
        if (items instanceof Array) {
            encodedItems = this.encodeList(items);
        } else {
            encodedItems = this.encodeItem(items);
        }
        const response: any = {data: encodedItems, included: this.included};
        return response;
    }

    encodeList(items: Array<any>) {
        return items.map(item => this.encodeItem(item));
    }

    encodeItem(item) {
        const response: JsonObject = <any>{};

        // Add attributes as properties
        for (const key in item) {
            if (this.isNotEmpty(item[key])) {
                if (key === 'id' || key === 'type') {
                    response[key] = item[key];
                } else if (item[key] instanceof Object && item[key].type) {
                    if (response.relationships == null) {
                        response.relationships = {};
                    }
                    response.relationships[key] = {data: this.addRelationship(item[key])};
                } else if (item[key] instanceof Array && (item[key][0] && item[key][0].type)) {
                    if (response.relationships == null) {
                        response.relationships = {};
                    }
                    response.relationships[key] = {data: this.addRelationships(item[key])};
                } else {
                    if (response.attributes == null) {
                        response.attributes = {};
                    }
                    response.attributes[key] = item[key];
                }
            }
        }
        return response;
    }

    private isNotEmpty(item: any): boolean {
        if (item instanceof Object) {
            return Object.keys(item).some(key => item[key] && key !== 'type');
        } else {
            return !!item;
        }
    }

    addRelationships(items: Array<any>): Array<any> {
        return items.map(item => this.addRelationship(item));
    }

    addRelationship(item: any): any {
        const response: any = <any>{};
        for (const key in item) {
            if (this.isNotEmpty(item[key])) {
                if (key === 'id' || key === 'type') {
                    response[key] = item[key];
                } else if (item[key] instanceof Object && item[key].type) {
                    if (response.relationships == null) {
                        response.relationships = {};
                    }
                    response.relationships[key] = {data: this.addRelationship(item[key])};
                } else {
                    if (response.attributes == null) {
                        response.attributes = {};
                    }
                    response.attributes[key] = item[key];
                }
            }
        }
        this.includeItem(item);
        return response;
    }

    // Add to includes
    includeItem(item) {
        this.included.push(this.encodeItem(item));
    }
}

