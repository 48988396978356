import {AppCountry} from './app-country';
import {AppLanguageLv} from './app-language-lv';
import {AppLanguageEn} from './app-language-en';

export class AppCountryLv extends AppCountry {

    constructor() {
        super({
            id: 'lv',
            name: 'Latvija',
            language: {
                default: new AppLanguageEn(),
                available: [
                    new AppLanguageLv(),
                    new AppLanguageEn()
                ]
            }
        });
    }
}
