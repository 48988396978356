export class RequestUrlBuilder {

    private url: string;
    private params: UrlParams;

    constructor(url: string, params: UrlParams = null) {
        this.url = url;
        this.params = params;
    }

    public urlWithParams(): string {
        return this.params ? this.url + '?' + this.paramsAsString() : this.url;
    }

    private paramsAsString(): string {
        let paramsArray = this.paramsAsArray();
        paramsArray = this.orderParams(paramsArray);
        return this.arrayToUrlParams(paramsArray);
    }

    private paramsAsArray(): UrlParams[] {
        const paramsArray = [];
        for (const paramName in this.params) {
            if (this.params.hasOwnProperty(paramName)) {
                const param = {};
                param[paramName] = this.params[paramName];
                paramsArray.push(param);
            }
        }
        return paramsArray;
    }

    private arrayToUrlParams(paramArray: UrlParams[]): string {
        return paramArray.map(param => {
            const paramName = Object.keys(param)[0];
            return [paramName, param[paramName]].map(encodeURIComponent).join('=');
        }).join('&');
    }

    private orderParams(paramsArray: UrlParams[]): UrlParams[] {
        let paramsOrderArray = paramsArray.map(checkParam => Object.keys(checkParam)[0]);
        paramsOrderArray = this.sortAlphabetically(paramsOrderArray);
        return paramsOrderArray.map(paramName => {
            return paramsArray.find(checkParam => Object.keys(checkParam)[0] === paramName);
        });
    }

    private sortAlphabetically(array: string[]): string[] {
        return array.sort((a, b) => {
            const valueA = a.toLowerCase();
            const valueB = b.toLowerCase();
            if (valueA < valueB) {
                return -1;
            }
            if (valueA > valueB) {
                return 1;
            }
            return 0;
        });
    }

}

export interface UrlParams {
    [name: string]: string;
}
