import {UrlParams} from '../../shared/services/request.service';

export class ApiFilter {
    private filters: UrlParams = {};

    constructor() {
    }

    public addFilter(key: string, value: string): ApiFilter {
        this.filters['filter[' + key + ']'] = value;
        return this;
    }

    public urlParams(): UrlParams {
        return this.filters;
    }
}
