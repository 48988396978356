import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {makeStateKey, StateKey, TransferState} from '@angular/platform-browser';
import {isPlatformServer} from '@angular/common';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {ApiService} from '../../api/services/api.service';
import {Feedback} from '../../api/interfaces/resources/feedback';
import {LocalSettingsService} from '../../system/services/local-settings.service';
import {ApiFilter} from '../../api/library/api-filter';

@Injectable({
    providedIn: 'root',
})
export class FeedbacksService {

    private localFeedbacks: Feedback[];
    private fetchFeedbacksHash: string = '';

    constructor(
        @Inject(PLATFORM_ID) private platformId: string,
        private transferState: TransferState,
        private apiService: ApiService,
        private localSettingsService: LocalSettingsService,
    ) {
    }

    public init(): Observable<void> {
        const feedbacksJsonFromServer = this.getFromTransferState(makeStateKey('feedbacks'));
        this.fetchFeedbacksHash = this.getFromTransferState(makeStateKey('fetchFeedbacksHash')) || '';
        this.localFeedbacks = feedbacksJsonFromServer ? JSON.parse(feedbacksJsonFromServer) : null;
        return of(null);
    }

    public feedbacks(): Observable<Feedback[]> {
        const filter = new ApiFilter().addFilter('language', this.localSettingsService.selectedLanguage().id());
        return !!this.localFeedbacks && this.fetchFeedbacksHash === this.filterHash(filter)
            ? of(this.localFeedbacks)
            : this.fetchFeedbacks(filter);
    }

    private fetchFeedbacks(filter: ApiFilter): Observable<Feedback[]> {
        return this.apiService.getFeedbacks(filter).pipe(
            map((feedbacks: Feedback[]) => {
                this.fetchFeedbacksHash = this.filterHash(filter);
                this.localFeedbacks = feedbacks;
                if (isPlatformServer(this.platformId)) {
                    this.setTransferState(makeStateKey('fetchFeedbacksHash'), this.fetchFeedbacksHash);
                    this.setTransferState(makeStateKey('feedbacks'), JSON.stringify(this.localFeedbacks));
                }
                return feedbacks;
            }),
        );
    }

    private filterHash(filter: ApiFilter): string {
        return Object.keys(filter.urlParams()).map(key => key + '=' + filter.urlParams()[key]).join('&');
    }

    private setTransferState(stateKey: StateKey<string>, content: string): void {
        this.transferState.set(stateKey, content);
    }

    private getFromTransferState(stateKey: StateKey<string>): string {
        return this.transferState.get(stateKey, null) || null;
    }
}
