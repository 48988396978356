import {deserialize} from 'jsonapi-deserializer';

export * from './encoder';

export class Jsonapi {

    public included: any[];

    public decode(json: any): any {
        return deserialize(json);
    }

    public camelCase(input: string): string {
        return input.replace(/[-_](.)/g, function (match, group1) {
            return group1.toUpperCase();
        });
    }

    public camelCaseObject(object: Object): Object {
        const camelCasedObject: Object = {};
        for (const i in object) {
            if (i in object) {
                const key = this.camelCase(i);
                if (typeof object[i] === 'object') {
                    camelCasedObject[key] = this.camelCaseObject(object[i]);
                } else {
                    camelCasedObject[key] = object[i];
                }
            }
        }
        return camelCasedObject;
    }

}
