<ng-template #linkContent>
    <ng-content select="app-icon"></ng-content>
    <ng-content></ng-content>
    <app-spinner *ngIf="isLoading" [size]="'small'"></app-spinner>
</ng-template>
<ng-container *ngIf="!disabled">
    <a *ngIf="hasSpecialSchema"
       href="{{link}}"
       class="link color-{{color}}"
       [ngClass]="{'disabled': isLoading, 'selected': selected, 'highlighted': highlighted}"
       (click)="click()">
        <ng-container *ngTemplateOutlet="linkContent"></ng-container>
    </a>
    <ng-container *ngIf="!hasSpecialSchema">
        <a *ngIf="!isExternal"
           [routerLink]="link"
           class="link color-{{color}}"
           [ngClass]="{'disabled': isLoading, 'selected': selected, 'highlighted': highlighted}"
           (click)="click()">
            <ng-container *ngTemplateOutlet="linkContent"></ng-container>
        </a>
        <a *ngIf="isExternal"
           href="{{link}}"
           target="_blank"
           class="link color-{{color}}"
           rel="noopener"
           [ngClass]="{'disabled': isLoading, 'selected': selected, 'highlighted': highlighted}"
           (click)="click()">
            <ng-container *ngTemplateOutlet="linkContent"></ng-container>
        </a>
    </ng-container>
</ng-container>
<div *ngIf="disabled" class="link color-{{color}} disabled">
    <ng-container *ngTemplateOutlet="linkContent"></ng-container>
</div>
