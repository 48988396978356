<div [ngClass]="{'opened': isOpened()}"
     [ngClass.gt-xs]="{'opened': isOpened(), 'desktop-screen': true}"
     class="wrapper {{openedModalType()}}-box">
    <div class="container">
        <app-link class="button close" (whenClick)="close()">
            <app-icon [name]="'close-outline'"></app-icon>
        </app-link>
        <div class="content">
            <ng-template #container></ng-template>
        </div>
    </div>
</div>
