import {ComponentRef, Inject, Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {ToastMessage} from '../interfaces/toast-message';
import {DOCUMENT} from '@angular/common';
import {ToastMessageComponent} from '../components/toast-message/toast-message.component';
import {ToastComponent} from '../components/toast/toast.component';
import {ToastMessageBuilder} from '../builders/toast-message-builder';

@Injectable({
    providedIn: 'root'
})
export class ToastService {

    private localIsOpened = false;
    private componentRef: ComponentRef<any>;
    private toastComponent: ToastComponent;
    private renderer: Renderer2;

    constructor(@Inject(DOCUMENT) document, rendererFactory: RendererFactory2) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    public showSuccess(message: string): void {
        const toastMessage = new ToastMessageBuilder()
            .setMessage(message)
            .setIcon('checkmark-done')
            .setStyle('success')
            .setPosition('top')
            .setAutoHideMs(5000)
            .build();
        this.show(toastMessage);
    }

    public showError(message: string): void {
        const toastMessage = new ToastMessageBuilder()
            .setMessage(message)
            .setIcon('warning-outline')
            .setStyle('error')
            .setPosition('top')
            .setAutoHideMs(5000)
            .build();
        this.show(toastMessage);
    }

    public show(toastMessage: ToastMessage): void {
        this.open(toastMessage);
    }

    public hide(): void {
        this.close();
    }

    public isOpened(): boolean {
        return this.localIsOpened;
    }

    public setHost(toastComponent: ToastComponent): void {
        this.toastComponent = toastComponent;
    }

    private open(toastMessage: ToastMessage): void {
        if (this.isOpened()) {
            this.closeImmediately();
        }
        this.componentRef = this.toastComponent.createComponent(ToastMessageComponent);
        this.localIsOpened = true;
        this.updateBodyClass();
        this.componentRef.instance.toastMessage = toastMessage;
        this.componentRef.instance.componentRef = this.componentRef;
    }

    private close(data?: any): void {
        if (this.isOpened()) {
            this.localIsOpened = false;
            this.updateBodyClass();
            setTimeout(() => {
                this.componentRef.destroy();
                this.componentRef = null;
            }, 300);
        }
    }

    private closeImmediately(): void {
        if (this.isOpened()) {
            this.localIsOpened = false;
            this.updateBodyClass();
            this.componentRef.destroy();
        }
    }

    private updateBodyClass(): void {
        const toastOpenedClass = 'toast-opened';
        this.isOpened()
            ? this.renderer.addClass(document.body, toastOpenedClass)
            : this.renderer.removeClass(document.body, toastOpenedClass);
    }
}
