import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HasLanguageSetGuard} from './system/guards/has-language-set.guard';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        loadChildren: () => import('./home/home.module').then(module => module.HomeModule),
        canActivate: [HasLanguageSetGuard]
    },
    {
        path: ':language',
        canActivateChild: [HasLanguageSetGuard],
        children: [
            {
                path: '',
                loadChildren: () => import('./home/home.module').then(module => module.HomeModule)
            }
        ]
    },
    {
        path: '**',
        redirectTo: '',
        pathMatch: 'full'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', initialNavigation: 'enabled' })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
