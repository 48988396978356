import {AppLanguage} from './app-language';

export class AppCountry {

    private countryParams: CountryParams;

    constructor(countryParams: CountryParams) {
        this.countryParams = countryParams;
    }

    public id(): string {
        return this.countryParams.id;
    }

    public name(): string {
        return this.countryParams.name;
    }

    public defaultLanguage(): AppLanguage {
        return this.countryParams.language.default;
    }

    public availableLanguages(): AppLanguage[] {
        return this.countryParams.language.available;
    }
}

interface CountryParams {
    id: string;
    name: string;
    language: {
        default: AppLanguage;
        available: AppLanguage[]
    };
}
