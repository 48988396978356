import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Link} from '../../library/link';

@Component({
    selector: 'app-link',
    templateUrl: './link.component.html',
    styleUrls: ['./link.component.scss']
})
export class LinkComponent implements OnInit {

    public isExternal = false;
    public hasSpecialSchema = false;

    @Input() isLoading: boolean;
    @Input() disabled: boolean;
    @Input() selected: boolean;
    @Input() link: string | string[];
    @Input() highlighted = false;
    @Input() color: 'primary' | 'dark' | 'light';

    @Output() whenClick = new EventEmitter<void>();

    constructor() {
    }

    public ngOnInit(): void {
        const link = new Link(this.link);
        this.isExternal = link.isExternal();
        this.hasSpecialSchema = link.hasSpecialSchema();
    }

    public click(): void {
        if (!this.isLoading && !this.disabled) {
            this.whenClick.emit();
        }
    }
}
