<div class="wrapper"
     *ngIf="isOpened"
     [@flyIn]="toastMessage.position"
     [@flyOut]="toastMessage.position"
     (@flyOut.done)="animationOutDone($event)">
    <div class="container">
        <app-icon *ngIf="toastMessage.icon" class="icon" [name]="toastMessage.icon"></app-icon>
        <div class="text">{{toastMessage.message}}</div>
    </div>
</div>
