import {Component, ComponentRef, HostBinding, Input, OnInit} from '@angular/core';
import {ToastMessage} from '../../interfaces/toast-message';
import {animate, style, transition, trigger, AnimationEvent} from '@angular/animations';

@Component({
    selector: 'app-toast-message',
    templateUrl: './toast-message.component.html',
    styleUrls: ['./toast-message.component.scss'],
    animations: [
        trigger('flyIn', [
            transition('void => top', [
                style({top: '-150px'}),
                animate(350, style({top: '*'}))
            ]),
            transition('void => bottom', [
                style({bottom: '-150px'}),
                animate(350, style({bottom: '*'}))
            ])
        ]),
        trigger('flyOut', [
            transition('top => void', [
                style({top: '*'}),
                animate(350, style({top: '-150px'}))
            ]),
            transition('bottom => void', [
                style({bottom: '*'}),
                animate(350, style({bottom: '-150px'}))
            ])
        ])
    ]
})
export class ToastMessageComponent implements OnInit {

    public isOpened = false;
    private localToastMessage: ToastMessage;

    @Input()
    set toastMessage(toastMessage: ToastMessage) {
        this.localToastMessage = toastMessage;
        this.setPosition();
        this.setStyle();
    }

    get toastMessage(): ToastMessage {
        return this.localToastMessage;
    }

    @Input() componentRef: ComponentRef<any>;

    @HostBinding('class.style-default') classStyleDefault = false;
    @HostBinding('class.style-success') classStyleSuccess = false;
    @HostBinding('class.style-error') classStyleError = false;
    @HostBinding('class.position-bottom') classPositionBottom = false;
    @HostBinding('class.position-top') classPositionTop = false;

    constructor() {
    }

    ngOnInit(): void {
        this.isOpened = true;
        this.autoHide();
    }

    public animationOutDone(event: AnimationEvent): void {
        if (event.toState === 'void') {
            this.componentRef.destroy();
        }
    }

    private autoHide(): void {
        setTimeout(() => {
            this.isOpened = false;
        }, this.toastMessage.autoHideMs);
    }

    private setPosition(): void {
        switch (this.toastMessage.position) {
            case 'bottom':
                this.classPositionBottom = true;
                break;
            case 'top':
                this.classPositionTop = true;
                break;
            default:
                this.classPositionBottom = true;
        }
    }

    private setStyle(): void {
        switch (this.toastMessage.style) {
            case 'success':
                this.classStyleSuccess = true;
                break;
            case 'error':
                this.classStyleError = true;
                break;
            default:
                this.classStyleDefault = true;
        }
    }
}
