import {Injectable} from '@angular/core';
import {Location, registerLocaleData} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {Observable, of, Subject} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';
import localeLv from '@angular/common/locales/lv';
import localeEn from '@angular/common/locales/en';
import {StorageService} from '../../shared/services/storage.service';
import {AppCountry} from '../library/app-country';
import {AppLanguage} from '../library/app-language';
import {AppLanguageEn} from '../library/app-language-en';
import {AppCountryLv} from '../library/app-country-lv';

@Injectable({
    providedIn: 'root',
})
export class LocalSettingsService {

    public whenCountryChanged: Subject<void> = new Subject();
    public whenLanguageChanged: Subject<void> = new Subject();
    private storageKey = 'localSettings';
    private localSelectedCountry: AppCountry;
    private localSelectedLanguage: AppLanguage;

    constructor(private storageService: StorageService,
                private translateService: TranslateService,
                private location: Location) {
    }

    public init(): Observable<void> {
        registerLocaleData(localeLv);
        registerLocaleData(localeEn);
        return this.storageService.get(this.storageKey).pipe(map(localSettingsJson => {
            const languageFromUrl = this.languageFromUrl();
            let languageFromStorage;
            if (localSettingsJson) {
                const localSettings: LocalSettings = JSON.parse(localSettingsJson);
                languageFromStorage = this.availableLanguages().find(lang => lang.id() === localSettings.language);
            }
            const selectedLanguage = languageFromUrl || languageFromStorage || this.selectedCountry().defaultLanguage();
            this.setLanguage(selectedLanguage).subscribe();
        }));
    }

    public selectedCountry(): AppCountry {
        return this.localSelectedCountry || new AppCountryLv();
    }

    public selectedLanguage(): AppLanguage {
        return this.localSelectedLanguage || new AppLanguageEn();
    }

    public availableLanguages(): AppLanguage[] {
        return this.selectedCountry().availableLanguages() || [];
    }

    public changeCountry(country: AppCountry): void {
        this.setCountry(country).subscribe();
    }

    public changeLanguage(language: AppLanguage): void {
        this.setLanguage(language).pipe(
            mergeMap(() => this.updateStorage()),
            map(() => this.redirectToLanguageUrl(language)),
            map(() => this.whenLanguageChanged.next()),
        ).subscribe();
    }

    public languageFromUrl(): AppLanguage {
        const urlParts = this.location.path(false).replace(/^\/|\/$/g, '').split('/');
        const languageFromUrl = this.availableLanguages().find(language => language.id() === urlParts[0]);
        return languageFromUrl ? languageFromUrl : null;
    }

    private setCountry(country: AppCountry): Observable<void> {
        if (country !== this.selectedCountry()) {
            this.localSelectedCountry = country;
            this.whenCountryChanged.next();
        }
        return of(null);
    }

    private setLanguage(language: AppLanguage): Observable<void> {
        if (language !== this.selectedLanguage()) {
            this.localSelectedLanguage = language;
            return this.translateService.use(this.selectedLanguage().id());
        } else {
            return of(null);
        }
    }

    private redirectToLanguageUrl(language: AppLanguage): void {
        const currentLanguageFromUrl = this.languageFromUrl();
        this.location.go(this.location.path()
            .split('/')
            .map(segment => segment === currentLanguageFromUrl.id() ? language.id() : segment)
            .join('/'));
    }

    private updateStorage(): Observable<void> {
        const localSettings = JSON.stringify({
            language: this.selectedLanguage().id(),
        });
        return this.storageService.set(this.storageKey, localSettings);
    }
}

interface LocalSettings {
    country: string;
    language: string;
}
