import {Injectable} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Jsonapi, JsonEncoder} from '../library/jsonapi/jsonapi';
import {RequestOptions, RequestService} from '../../shared/services/request.service';
import {Reservation} from '../interfaces/resources/reservation';
import {Subscription} from '../interfaces/resources/subscription';
import {Feedback} from '../interfaces/resources/feedback';
import {ApiUrls} from '../library/api-urls';
import {ApiFilter} from '../library/api-filter';

@Injectable({
    providedIn: 'root',
})
export class ApiService {

    private jsonapi: Jsonapi = new Jsonapi();
    private jsonApiEncoder = new JsonEncoder();
    private apiUrls: ApiUrls = new ApiUrls();

    constructor(private requestService: RequestService) {
    }

    public postReservation(reservation: Reservation): Observable<Reservation> {
        const requestOptions: RequestOptions = {
            url: this.apiUrls.reservations(),
            body: {data: this.jsonApiEncoder.encodeItem(reservation)},
            responseType: 'json',
        };
        return this.makePostRequestAndReturnResponse(requestOptions);
    }

    public postSubscription(subscription: Subscription): Observable<Subscription> {
        const requestOptions: RequestOptions = {
            url: this.apiUrls.subscriptions(),
            body: {data: this.jsonApiEncoder.encodeItem(subscription)},
            responseType: 'json',
        };
        return this.makePostRequestAndReturnResponse(requestOptions);
    }

    public getFeedbacks(filter: ApiFilter): Observable<Feedback[]> {
        const requestOptions: RequestOptions = {
            url: this.apiUrls.feedbacks(),
            urlParams: filter.urlParams(),
            responseType: 'json',
        };
        return this.makeGetRequestAndReturnResponse(requestOptions);
    }

    private makeGetRequestAndReturnResponse(requestOptions: RequestOptions): Observable<any> {
        return this.requestService.get(requestOptions).pipe(
            map(response => {
                if (this.isResponseSuccess(response)) {
                    return response.body ? this.jsonapi.decode(response.body) : null;
                } else {
                    throw response;
                }
            }),
        );
    }

    private makePostRequestAndReturnResponse(requestOptions: RequestOptions): Observable<any> {
        return this.requestService.post(requestOptions).pipe(
            map(response => {
                if (this.isResponseSuccess(response)) {
                    return response.body ? this.jsonapi.decode(response.body) : null;
                } else {
                    throw response;
                }
            }),
        );
    }

    private isResponseSuccess(response: HttpResponse<any>): boolean {
        const successCodes = [200, 201, 202, 204];
        return response && successCodes.indexOf(response.status) !== -1;
    }
}
