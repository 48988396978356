import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import {ToastComponent} from './components/toast/toast.component';
import {ToastMessageComponent} from './components/toast-message/toast-message.component';

@NgModule({
    declarations: [
        ToastComponent,
        ToastMessageComponent
    ],
    imports: [
        CommonModule,
        SharedModule
    ],
    exports: [
        ToastComponent
    ]
})
export class ToastModule {
}
