import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {StorageMap} from '@ngx-pwa/local-storage';

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    constructor(private storageMap: StorageMap) {
    }

    public init(): Observable<void> {
        return of(null);
    }

    public set(key: string, value: string): Observable<void> {
        return this.storageMap.set(key, value);
    }

    public get(key: string): Observable<string> {
        return this.storageMap.get(key) as Observable<string>;
    }

    public remove(key: string): Observable<void> {
        return this.storageMap.delete(key);
    }

    public clear(): Observable<void> {
        return this.storageMap.clear();
    }
}
