import {ComponentRef, Inject, Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Observable, ReplaySubject} from 'rxjs';
import {ModalComponent} from '../components/modal/modal.component';

@Injectable({
    providedIn: 'root'
})
export class ModalService {

    private localIsOpened = false;
    private localOpenedModalType: string;
    private componentRef: ComponentRef<any>;
    private modalComponent: ModalComponent;
    private modalSubject: ReplaySubject<any>;
    private renderer: Renderer2;

    constructor(@Inject(DOCUMENT) document, rendererFactory: RendererFactory2) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    public showPopup(component: ComponentRef<any> | any, componentProps?: ComponentInputs): Observable<any> {
        if (this.isOpened()) {
            this.closeImmediately();
        }
        this.localOpenedModalType = 'popup';
        this.open(component, componentProps);
        return this.modalSubject;
    }

    public hide(data?: any): void {
        this.close(data);
    }

    public isOpened(): boolean {
        return this.localIsOpened;
    }

    public openedModalType(): string {
        return this.localOpenedModalType;
    }

    public setHost(modalComponent: ModalComponent): void {
        this.modalComponent = modalComponent;
    }

    private open(component: ComponentRef<any> | any, componentProps?: ComponentInputs): void {
        this.modalSubject = new ReplaySubject();
        if (!this.isOpened()) {
            this.componentRef = this.modalComponent.createComponent(component);
            this.localIsOpened = true;
            this.updateBodyClass();
            Object.keys(componentProps || {}).map(key => {
                this.componentRef.instance[key] = componentProps[key];
            });
        }
    }

    private close(data?: any): void {
        if (this.isOpened()) {
            this.localIsOpened = false;
            this.updateBodyClass();
            this.modalSubject.next(data);
            setTimeout(() => {
                this.componentRef.destroy();
                this.componentRef = null;
            }, 300);
        }
    }

    private closeImmediately(): void {
        if (this.isOpened()) {
            this.localIsOpened = false;
            this.updateBodyClass();
            this.modalSubject.next(null);
            this.componentRef.destroy();
        }
    }

    private updateBodyClass(): void {
        const modalOpenedClass = 'modal-opened';
        this.isOpened()
            ? this.renderer.addClass(document.body, modalOpenedClass)
            : this.renderer.removeClass(document.body, modalOpenedClass);
    }
}

interface ComponentInputs {
    [name: string]: any;
}
