import {AppLanguage} from './app-language';

export class AppLanguageEn extends AppLanguage {

    constructor() {
        super({
            id: 'en',
            name: 'English',
            localeId: 'en-US',
            iconName: 'flags/gb'
        });
    }
}
