import {ToastMessage} from '../interfaces/toast-message';

export class ToastMessageBuilder {

    private toastMessage: ToastMessage = {
        message: '',
        icon: null,
        position: 'bottom',
        style: 'default',
        autoHideMs: 3000
    };

    public setMessage(message: string): this {
        this.toastMessage.message = message;
        return this;
    }

    public setIcon(icon: string): this {
        this.toastMessage.icon = icon;
        return this;
    }

    public setPosition(position: 'top' | 'bottom'): this {
        this.toastMessage.position = position;
        return this;
    }

    public setStyle(style: string): this {
        this.toastMessage.style = style;
        return this;
    }

    public setAutoHideMs(autoHideMs: number): this {
        this.toastMessage.autoHideMs = autoHideMs;
        return this;
    }

    public build(): ToastMessage {
        return this.toastMessage;
    }
}
