import {environment} from '@project-environments/environment';

export class ApiUrls {

    public reservations(id?: string): string {
        return this.makeUrl('reservations', id);
    }

    public subscriptions(id?: string): string {
        return this.makeUrl('subscriptions', id);
    }

    public feedbacks(id?: string): string {
        return this.makeUrl('feedbacks', id);
    }

    private makeUrl(url: string, id?: string): string {
        if (id) {
            url = url + '/' + id;
        }
        return environment.apiBase + '/' + url;
    }
}
