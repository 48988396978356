import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {LvSettingsService} from './system/services/lv-settings.service';
import {StorageService} from './shared/services/storage.service';
import {LocalSettingsService} from './system/services/local-settings.service';
import {AppErrorHandlerService} from './shared/services/app-error-handler.service';
import {AppMetaService} from './shared/services/app-meta.service';
import {FeedbacksService} from './home/services/feedbacks.service';

@Injectable()
export class AppInitializer {

    constructor(
        private appMetaService: AppMetaService,
        private storageService: StorageService,
        private appErrorHandlerService: AppErrorHandlerService,
        private localSettingsService: LocalSettingsService,
        private lvSettingsService: LvSettingsService,
        private feedbacksService: FeedbacksService,
    ) {
    }

    public init(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const subscription = this.initServices().subscribe(() => {
                setTimeout(() => subscription.unsubscribe());
                resolve(true);
            });
        });
    }

    private initServices(): Observable<void> {
        return of(null).pipe(
            mergeMap(() => this.initAppServices()),
            mergeMap(() => this.initBusinessServices()),
        );
    }

    private initAppServices(): Observable<any> {
        return of(null).pipe(
            mergeMap(() => this.storageService.init()),
            mergeMap(() => this.lvSettingsService.init()),
            mergeMap(() => this.localSettingsService.init()),
            mergeMap(() => this.appMetaService.init()),
            mergeMap(() => this.appErrorHandlerService.init()),
        );
    }

    private initBusinessServices(): Observable<any> {
        return of(null).pipe(
            mergeMap(() => this.feedbacksService.init()),
        );
    }
}
