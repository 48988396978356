export class Link {

    private readonly localLink: string | string[];

    constructor(link: string | string[]) {
        this.localLink = link;
    }

    public url(): string | string[] {
        return this.localLink;
    }

    public isExternal(): boolean {
        const pattern = new RegExp('^(http|https):');
        return (typeof this.localLink === 'string') && pattern.test(this.localLink);
    }

    public hasSpecialSchema(): boolean {
        const pattern = new RegExp('^(http|https):');
        return (typeof this.localLink === 'string') && !pattern.test(this.localLink);
    }
}
