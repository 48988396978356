import {Component, ElementRef, Input, OnInit, Renderer2} from '@angular/core';

@Component({
    selector: 'app-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {

    public svgUrl = '/assets/images/';

    @Input() name = '';
    @Input() size: 'small' | 'medium' | 'large' = 'small';

    constructor(private renderer: Renderer2, private elementRef: ElementRef) {
    }

    public ngOnInit(): void {
        this.renderer.addClass(this.elementRef.nativeElement, 'size-' + this.size);
    }

    public icon(): string {
        const svgUrl = this.svgUrl + (this.name.indexOf('/') === -1 ? 'icons/' : '');
        return svgUrl + this.name + '.svg';
    }
}
