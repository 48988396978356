import {Injectable} from '@angular/core';
import {registerLocaleData} from '@angular/common';
import {Observable, of} from 'rxjs';
import localeLv from '@angular/common/locales/lv';
import localeEn from '@angular/common/locales/en';
import {LocalSettingsService} from './local-settings.service';
import {AppCountryLv} from '../library/app-country-lv';

@Injectable({
    providedIn: 'root'
})
export class LvSettingsService {

    constructor(private localSettingsService: LocalSettingsService) {
    }

    public init(): Observable<void> {
        registerLocaleData(localeLv);
        registerLocaleData(localeEn);
        this.localSettingsService.changeCountry(new AppCountryLv());
        return of(null);
    }
}
