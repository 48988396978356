import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Observable, of, Subject} from 'rxjs';
import {map, catchError, timeout} from 'rxjs/operators';
import {RequestUrlBuilder} from '../library/request-url-builder';
import {LocalSettingsService} from '../../system/services/local-settings.service';
import {environment} from '@project-environments/environment';

@Injectable({
    providedIn: 'root'
})
export class RequestService {

    public whenNetworkError = new Subject<void>();

    private defaultContentType = 'application/json';

    constructor(private http: HttpClient,
                private localSettingsService: LocalSettingsService) {
    }

    public get(requestOptions: RequestOptions): Observable<HttpResponse<any>> {
        return this.request('get', requestOptions);
    }

    public post(requestOptions: RequestOptions): Observable<HttpResponse<any>> {
        return this.request('post', requestOptions);
    }

    public patch(requestOptions: RequestOptions): Observable<HttpResponse<any>> {
        return this.request('patch', requestOptions);
    }

    public delete(requestOptions: RequestOptions): Observable<HttpResponse<any>> {
        return this.request('delete', requestOptions);
    }

    private request(method: string, requestOptions: RequestOptions): Observable<HttpResponse<any>> {
        const requestHeaders = this.requestHeaders({...this.defaultHeaders(), ...requestOptions.headers});
        const requestUrlBuilder = new RequestUrlBuilder(requestOptions.url, requestOptions.urlParams as UrlParams);
        const requestBody = this.requestBody(requestOptions.body, requestOptions.headers);
        const requestUrl = requestUrlBuilder.urlWithParams();
        const responseType: any = requestOptions.responseType || 'arrayBuffer';
        let request;
        switch (method) {
            case 'get':
                request = this.http.get(requestUrl, {
                    headers: requestHeaders,
                    observe: 'response',
                    responseType
                });
                break;
            case 'post':
                request = this.http.post(requestUrl, requestBody, {
                    headers: requestHeaders,
                    observe: 'response',
                    responseType
                });
                break;
            case 'patch':
                request = this.http.patch(requestUrl, requestBody, {
                    headers: requestHeaders,
                    observe: 'response',
                    responseType
                });
                break;
            case 'delete':
                request = this.http.delete(requestUrl, {
                    headers: requestHeaders,
                    observe: 'response',
                    responseType
                });
                break;
        }
        return method === 'get' ? this.processGetDataRequest(request) : this.processUpdateDataRequest(request);
    }

    private processGetDataRequest(request: Observable<HttpResponse<any>>): Observable<HttpResponse<any>> {
        return request.pipe(
            timeout(30 * 1000),
            catchError(error => {
                if (error.status === 408) {
                    this.whenNetworkError.next();
                }
                return of(error);
            }),
            map((responseFromRequest: HttpResponse<any>) => {
                return responseFromRequest;
            })
        );
    }

    private processUpdateDataRequest(request: Observable<HttpResponse<any>>): Observable<HttpResponse<any>> {
        return request.pipe(
            catchError(error => {
                return of(error);
            }),
            map((responseFromRequest: HttpResponse<any>) => {
                return responseFromRequest;
            })
        );
    }

    private requestHeaders(headers: { [name: string]: string }): any {
        headers = headers || {};
        if (!this.isContentTypeHeaderSet(headers)) {
            headers = Object.assign(headers, {'Content-Type': this.defaultContentType});
        }
        return new HttpHeaders(headers);
    }

    private requestBody(body: { [name: string]: string }, headers: RequestHeaders): any {
        if (!body) {
            return body;
        }
        const contentType = this.contentTypeFromHeaders(headers);
        let requestBody;
        if (contentType === 'application/x-www-form-urlencoded') {
            requestBody = this.objectToUrlParams(body);
        } else {
            requestBody = body;
        }
        return requestBody;
    }

    private defaultHeaders(): RequestHeaders {
        const language = this.localSettingsService.selectedLanguage();
        const country = this.localSettingsService.selectedCountry();
        return {
            'BACKBRO-locale': language.id().toLowerCase() + '-' + country.id().toUpperCase(),
            'BACKBRO-app-version': environment.appVersion
        };
    }

    private objectToUrlParams(object: { [name: string]: string }): string {
        return Object.keys(object)
            .map((key) => [key, object[key]].map(encodeURIComponent).join('=').replace(/%2C/g, ','))
            .join('&');
    }

    private contentTypeFromHeaders(headers: RequestHeaders): string {
        for (const i in headers) {
            if (i === 'Content-Type') {
                return headers[i];
            }
        }
        return this.defaultContentType;
    }

    private isContentTypeHeaderSet(headers: RequestHeaders): boolean {
        for (const i in headers) {
            if (i === 'Content-Type') {
                return true;
            }
        }
        return false;
    }
}

export interface RequestOptions {
    url: string;
    urlParams?: UrlParams;
    headers?: RequestHeaders;
    body?: { [name: string]: any };
    responseType?: 'arrayBuffer' | 'json' | 'text';
}

export interface UrlParams {
    [name: string]: string;
}

export interface RequestHeaders {
    [name: string]: string;
}
