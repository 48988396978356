import {Inject, Injectable, Injector} from '@angular/core';
import {Meta, MetaDefinition, Title} from '@angular/platform-browser';
import {NavigationEnd, Router, RouterEvent} from '@angular/router';
import {Observable, of} from 'rxjs';
import {map, filter} from 'rxjs/operators';
import {environment} from '@project-environments/environment';
import {LocalSettingsService} from '../../system/services/local-settings.service';
import {DOCUMENT} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class AppMetaService {

    constructor(private router: Router,
                private title: Title,
                private localSettingsService: LocalSettingsService,
                private translateService: TranslateService,
                @Inject(DOCUMENT) private document: Document,
                private meta: Meta) {
    }

    public init(): Observable<void> {
        this.updatePageLanguageOnLanguageChange();
        this.resetMetaOnRouterChange();
        this.updateWithDefaultMeta();
        return of(null);
    }

    public updateWithDefaultMeta(): void {
        this.removeAllMetaTags();
        this.title.setTitle(environment.appName);
        this.meta.addTag({
            name: 'description',
            content: ['ABOUT_PRODUCT.DESCRIPTION_1', 'ABOUT_PRODUCT.DESCRIPTION_2'].map(key => this.translateService.instant(key)).join(' ')
        }, true);
    }

    public updateWithCustomMeta(title: string, tags: MetaDefinition[] = []): void {
        this.title.setTitle(title);
        this.removeAllMetaTags();
        this.meta.addTags(tags, true);
    }

    private removeAllMetaTags(): void {
        this.meta.removeTag('name=\'keywords\'');
        this.meta.removeTag('name=\'description\'');
        this.meta.removeTag('property=\'og:site_name\'');
        this.meta.removeTag('property=\'og:title\'');
        this.meta.removeTag('property=\'og:type\'');
        this.meta.removeTag('property=\'og:url\'');
        this.meta.removeTag('property=\'og:description\'');
        this.meta.removeTag('property=\'og:image\'');
    }

    private updatePageLanguageOnLanguageChange(): void {
        this.localSettingsService.whenLanguageChanged.subscribe(() => {
            this.document.documentElement.lang = this.localSettingsService.selectedLanguage().id();
        });
    }

    private resetMetaOnRouterChange(): void {
        this.router.events.pipe(
            filter((event: RouterEvent) => event instanceof NavigationEnd),
            map(() => this.updateWithDefaultMeta())
        ).subscribe();
    }
}
