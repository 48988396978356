import {LocalSettingsService} from '../services/local-settings.service';
import {LOCALE_ID, StaticClassProvider} from '@angular/core';

export class DynamicLocaleId extends String {

    constructor(protected localSettingsService: LocalSettingsService) {
        super('');
    }

    toString(): string {
        return this.localSettingsService.selectedLanguage().localeId();
    }
}

export const DynamicLocaleProvider: StaticClassProvider = {
    provide: LOCALE_ID,
    useClass: DynamicLocaleId,
    deps: [LocalSettingsService]
};
