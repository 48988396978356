export const environment = {
    production: true,
    appVersion: '1.2.0',
    apiBase: 'https://api.backbro.eu',
    apiVersion: '1.0',
    appName: 'BACKBRO',
    appServerBaseHref: 'http://127.0.0.1:4000',
    shopUrl: 'https://shopbackbro.eu',
    errorHandlerDsn: ''
};
