import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import {MatSidenavModule} from '@angular/material/sidenav';
import {YouTubePlayerModule} from '@angular/youtube-player';
import {FlexLayoutModule} from '@angular/flex-layout';
import {InlineSVGModule} from 'ng-inline-svg';
import {AdjustUrlPipe} from './pipes/adjust-url.pipe';
import {CustomDatePipe} from './pipes/custom-date.pipe';
import {LogoComponent} from './components/logo/logo.component';
import {ActionButtonComponent} from './components/action-button/action-button.component';
import {IconComponent} from './components/icon/icon.component';
import {IconButtonComponent} from './components/icon-button/icon-button.component';
import {ImageComponent} from './components/image/image.component';
import {LinkComponent} from './components/link/link.component';
import {SpinnerComponent} from './components/spinner/spinner.component';
import {LanguageSwitcherComponent} from './components/language-switcher/language-switcher.component';
import {SocialProfilesComponent} from './components/social-profiles/social-profiles.component';
import {SideDrawerComponent} from './components/side-drawer/side-drawer.component';
import {SlidesComponent} from './components/slides/slides.component';
import {SlideComponent} from './components/slide/slide.component';
import {InputTextComponent} from './components/input-text/input-text.component';
import {YoutubePlayerComponent} from './components/youtube-player/youtube-player.component';
import {RatingComponent} from './components/rating/rating.component';


@NgModule({
    declarations: [
        LogoComponent,
        ActionButtonComponent,
        IconComponent,
        IconButtonComponent,
        ImageComponent,
        LinkComponent,
        SpinnerComponent,
        LanguageSwitcherComponent,
        SocialProfilesComponent,
        SideDrawerComponent,
        SlidesComponent,
        SlideComponent,
        InputTextComponent,
        AdjustUrlPipe,
        CustomDatePipe,
        YoutubePlayerComponent,
        RatingComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule,
        FlexLayoutModule,
        MatSidenavModule,
        InlineSVGModule.forRoot(),
        YouTubePlayerModule,
    ],
    exports: [
        LogoComponent,
        ActionButtonComponent,
        IconComponent,
        IconButtonComponent,
        ImageComponent,
        LinkComponent,
        SpinnerComponent,
        LanguageSwitcherComponent,
        SocialProfilesComponent,
        SideDrawerComponent,
        SlidesComponent,
        SlideComponent,
        InputTextComponent,
        YoutubePlayerComponent,
        RatingComponent,
        AdjustUrlPipe,
        CustomDatePipe,
    ],
})
export class SharedModule {
}
