import {TranslateLoader} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@project-environments/environment';
import {of} from 'rxjs';
import {StateKey, makeStateKey, TransferState} from '@angular/platform-browser';

export class TranslateBrowserLoader implements TranslateLoader {
    private appLanguagesPath = './i18n/';
    public suffix = '.json';

    constructor(private httpClient: HttpClient, private transferState: TransferState) {
    }

    public getTranslation(lang: string): any {
        const translationUrl = this.appLanguagesPath + lang + this.suffix + '?v' + environment.appVersion;
        const translationFromTransferState = this.getFromTransferState(lang);
        return translationFromTransferState ? of(translationFromTransferState) : this.httpClient.get(translationUrl);
    }

    private getFromTransferState(lang: string): any {
        const key: StateKey<string> = makeStateKey<string>(
            'transfer-translate-' + lang
        );
        const data = this.transferState.get(key, null);
        return data || null;
    }
}

export function translateBrowserLoaderFactory(httpClient: HttpClient, transferState: TransferState): TranslateBrowserLoader {
    return new TranslateBrowserLoader(httpClient, transferState);
}
