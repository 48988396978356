import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

    @Input() size: 'small' | 'medium' | 'large' = 'small';

    constructor() {
    }

    public ngOnInit(): void {
    }

    public diameter(): number {
        let diameter;
        switch (this.size) {
            case 'medium':
                diameter = 28;
                break;
            case 'large':
                diameter = 36;
                break;
            default:
                diameter = 20;
                break;
        }
        return diameter;
    }
}
