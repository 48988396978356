import {ErrorHandler, Injectable} from '@angular/core';
import * as Sentry from '@sentry/browser';
import {Observable, of} from 'rxjs';
import {environment} from '@project-environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AppErrorHandlerService {

    constructor() {
    }

    public init(): Observable<void> {
        Sentry.init({
            dsn: environment.errorHandlerDsn,
            release: environment.appVersion,
            environment: environment.production ? 'production' : 'development',
            ignoreErrors: []
        });
        this.configErrorReporting();
        return of(null);
    }

    public reportError(message: string): void {
        Sentry.captureMessage(message);
    }

    private configErrorReporting(): void {
        Sentry.configureScope(scope => {
        });
    }
}

export class ErrorHandlerWithReporting implements ErrorHandler {
    handleError(err: any): void {
        Sentry.captureException(err.originalError || err);
        console.error(err);
    }
}
