import {Component, ComponentFactoryResolver, ComponentRef, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {ToastService} from '../../services/toast.service';

@Component({
    selector: 'app-toast',
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit {

    @ViewChild('container', {read: ViewContainerRef}) containerViewContainerRef: ViewContainerRef;

    constructor(private toastService: ToastService,
                private componentFactoryResolver: ComponentFactoryResolver) {
    }

    ngOnInit(): void {
        this.toastService.setHost(this);
    }

    public createComponent(component: ComponentRef<any> | any): ComponentRef<any> {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
        return this.containerViewContainerRef.createComponent(componentFactory);
    }
}
