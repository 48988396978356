import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FlexLayoutModule} from '@angular/flex-layout';
import {ModalComponent} from './components/modal/modal.component';
import {SharedModule} from '../shared/shared.module';


@NgModule({
    declarations: [
        ModalComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        FlexLayoutModule
    ],
    exports: [
        ModalComponent
    ]
})
export class ModalModule {
}
