import {Component, ComponentFactoryResolver, ComponentRef, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {ModalService} from '../../services/modal.service';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

    @ViewChild('container', {read: ViewContainerRef}) containerViewContainerRef: ViewContainerRef;

    constructor(private modalService: ModalService,
                private componentFactoryResolver: ComponentFactoryResolver) {
    }

    ngOnInit(): void {
        this.modalService.setHost(this);
    }

    public isOpened(): boolean {
        return this.modalService.isOpened();
    }

    public openedModalType(): string {
        return this.modalService.openedModalType();
    }

    public createComponent(component: ComponentRef<any> | any): ComponentRef<any> {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
        return this.containerViewContainerRef.createComponent(componentFactory);
    }

    public close(): void {
        this.modalService.hide();
    }
}
