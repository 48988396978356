import {BrowserModule, BrowserTransferStateModule, TransferState} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';

import {AppComponent} from './app.component';
import {AppInitializer} from './app-initializer';
import {AppRoutingModule} from './app-routing.module';
import {ErrorHandlerWithReporting} from './shared/services/app-error-handler.service';
import {ModalModule} from './modal/modal.module';
import {ToastModule} from './toast/toast.module';
import {ApiModule} from './api/api.module';
import {translateBrowserLoaderFactory} from './shared/library/translate-browser-loader';
import {DynamicLocaleProvider} from './system/library/dynamic-locale-id';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        CommonModule,
        BrowserModule.withServerTransition({appId: 'serverApp'}),
        BrowserTransferStateModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        AppRoutingModule,
        ApiModule,
        ModalModule,
        ToastModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: translateBrowserLoaderFactory,
                deps: [HttpClient, TransferState]
            }
        })
    ],
    providers: [
        AppInitializer,
        DynamicLocaleProvider,
        {
            provide: APP_INITIALIZER,
            useFactory: (provider: AppInitializer) => () => provider.init(),
            deps: [AppInitializer],
            multi: true
        },
        {
            provide: ErrorHandler,
            useClass: ErrorHandlerWithReporting
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
